import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Blog.css'; // Import the CSS file
import CallToAction from '../../components/CallToAction/CallToAction';
import dynamoDb from '../../connections/aws-config'; // Import your DynamoDB config

function BlogPage() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchPosts = async () => {
        const params = {
            TableName: "smart-mark-blog",
            FilterExpression: "#status = :status",
            ExpressionAttributeNames: {
                "#status": "status", // Alias for reserved keyword
            },
            ExpressionAttributeValues: {
                ":status": "Published", // Only fetch published posts
            },
        };

        try {
            const data = await dynamoDb.scan(params).promise();
            setPosts(data.Items || []);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching blog posts:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    return (
        <div className="blog-page bg-gray-50 min-h-screen py-12 px-4">
            <div className="max-w-7xl mx-auto px-4 mb-16 text-center">
                <h1 className="text-5xl font-bold mb-6">Nuestro Blog</h1>
                <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                    Explora nuestras últimas publicaciones sobre estrategias de marketing, SEO, redes sociales y más.
                </p>
            </div>

            {loading ? (
                <p className="text-center text-gray-500">Cargando publicaciones...</p>
            ) : (
                <div className="blog-grid">
                {posts.map((post) => (
                    <div className="blog-card-1" key={post.id}>
                        {post.thumbnail_url && (
                            <div
                                className="blog-card-image"
                                style={{
                                    backgroundImage: `url(${post.thumbnail_url})`,
                                }}
                            ></div>
                        )}
                        <div className="blog-card-content">
                            <h3 className="blog-card-title">{post.title}</h3>
                            <p
                                className="blog-card-summary"
                                dangerouslySetInnerHTML={{ __html: post.summary }}
                            ></p>
                            <p className="blog-card-meta">
                                Por <strong>{post.author}</strong> -{" "}
                                {new Date(post.publish_at || post.created_at).toLocaleDateString()}
                            </p>
                            <button
                                className="blog-card-button"
                                onClick={() => navigate(`/post/${post.id}`)}
                            >
                                Leer más
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            )}
        </div>
    );
}

export default BlogPage;
