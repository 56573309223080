import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import './Blog.css'; // Import the CSS file
import dynamoDb from '../../../connections/aws-config'; // Adjust the path as needed

Modal.setAppElement("#root");

function Blog() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPost, setCurrentPost] = useState(null);

    // Fetch posts from DynamoDB
    const fetchPosts = async () => {
        const params = {
            TableName: "smart-mark-blog",
        };

        try {
            const data = await dynamoDb.scan(params).promise();
            setPosts(data.Items || []);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching blog posts:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    // Delete a blog post
    const deletePost = async (id) => {
        const params = {
            TableName: "smart-mark-blog",
            Key: {
                id: id,
            },
        };

        try {
            await dynamoDb.delete(params).promise();
            setPosts(posts.filter((post) => post.id !== id));
        } catch (error) {
            console.error("Error deleting blog post:", error);
        }
    };

    // Save (Create or Update) a blog post
    const savePost = async (e) => {
        e.preventDefault();
    
        const params = {
            TableName: "smart-mark-blog",
            Item: {
                id: currentPost.id || Date.now().toString(),
                title: currentPost.title,
                slug: currentPost.slug || currentPost.title.toLowerCase().replace(/\s+/g, '-'),
                summary: currentPost.summary,
                tags: Array.isArray(currentPost.tags)
                    ? currentPost.tags // Si ya es un array, úsalo tal cual
                    : currentPost.tags.split(',').map((tag) => tag.trim()), // Convierte una cadena en array
                author: currentPost.author,
                category: currentPost.category,
                status: currentPost.status,
                created_at: currentPost.created_at || new Date().toISOString(),
                updated_at: new Date().toISOString(),
                publish_at: currentPost.publish_at || null,
                thumbnail_url: currentPost.thumbnail_url || "",
                content: currentPost.content,
                views: currentPost.views || 0,
                likes: currentPost.likes || 0,
            },
        };
    
        try {
            await dynamoDb.put(params).promise();
            fetchPosts(); // Refresh the posts list
            setIsModalOpen(false); // Close the modal
            setCurrentPost(null); // Clear the current post state
        } catch (error) {
            console.error("Error saving blog post:", error);
        }
    };
    

    const openModal = (post = null) => {
        setCurrentPost(
            post
                ? {
                      ...post,
                      tags: Array.isArray(post.tags) ? post.tags.join(', ') : post.tags, // Convierte el array en una cadena separada por comas
                  }
                : {
                      title: "",
                      slug: "",
                      summary: "",
                      tags: "",
                      author: "",
                      category: "",
                      status: "Draft",
                      publish_at: "",
                      thumbnail_url: "",
                      content: "",
                      views: 0,
                      likes: 0,
                  }
        );
        setIsModalOpen(true); // Open the modal
    };
    

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentPost(null); // Clear the current post state
    };

    return (
        <section className="blog-management">
            <div className="blog-header">
                <h2 className="text-2xl font-bold mb-4">Gestión de Blog</h2>
                <button className="add-blog-btn" onClick={() => openModal()}>
                    Crear Nueva Publicación
                </button>
            </div>

            {loading ? (
                <p>Cargando publicaciones...</p>
            ) : (
                <div className="blog-grid">
                    {posts.map((post) => (
                        <div key={post.id} className="blog-card">
                            <div className="blog-info">
                                <h3 className="blog-title">{post.title}</h3>
                                <div className="blog-meta">
                                    <span className="blog-author">Autor: {post.author}</span>
                                    <span className="blog-date">
                                        Fecha: {new Date(post.created_at).toLocaleDateString()}
                                    </span>
                                </div>
                                <span className={`blog-status ${post.status.toLowerCase()}`}>
                                    {post.status}
                                </span>
                            </div>
                            <div className="blog-actions">
                                <button className="view-btn">Ver</button>
                                <button className="edit-btn" onClick={() => openModal(post)}>
                                    Editar
                                </button>
                                <button
                                    className="delete-btn"
                                    onClick={() => deletePost(post.id)}
                                >
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Modal for Create/Edit */}
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Publicación del Blog"
              className="modal wide-modal"
              overlayClassName="overlay"
          >
              <h2 className="modal-title">{currentPost?.id ? "Editar Publicación" : "Crear Nueva Publicación"}</h2>
              <form onSubmit={savePost} className="modal-form">
                  <div className="form-grid">
                      {/* Column 1 */}
                      <div>
                          <label>
                              Título:
                              <input
                                  type="text"
                                  value={currentPost?.title || ""}
                                  onChange={(e) =>
                                      setCurrentPost({ ...currentPost, title: e.target.value })
                                  }
                                  required
                              />
                          </label>
                          <label>
                              Tags (separados por comas):
                              <input
                                  type="text"
                                  value={currentPost?.tags || ""}
                                  onChange={(e) =>
                                      setCurrentPost({ ...currentPost, tags: e.target.value })
                                  }
                              />
                          </label>
                          <label>
                              Autor:
                              <input
                                  type="text"
                                  value={currentPost?.author || ""}
                                  onChange={(e) =>
                                      setCurrentPost({ ...currentPost, author: e.target.value })
                                  }
                                  required
                              />
                          </label>
                      </div>

                      {/* Column 2 */}
                      <div>
                          <label>
                              Categoría:
                              <input
                                  type="text"
                                  value={currentPost?.category || ""}
                                  onChange={(e) =>
                                      setCurrentPost({ ...currentPost, category: e.target.value })
                                  }
                                  required
                              />
                          </label>
                          <label>
                              Fecha de Publicación:
                              <input
                                  type="date"
                                  value={currentPost?.publish_at || ""}
                                  onChange={(e) =>
                                      setCurrentPost({ ...currentPost, publish_at: e.target.value })
                                  }
                              />
                          </label>
                          <label>
                              URL de Imagen (Thumbnail):
                              <input
                                  type="text"
                                  value={currentPost?.thumbnail_url || ""}
                                  onChange={(e) =>
                                      setCurrentPost({ ...currentPost, thumbnail_url: e.target.value })
                                  }
                              />
                          </label>
                          <label>
                              Estado:
                              <select
                                  value={currentPost?.status || "Draft"}
                                  onChange={(e) =>
                                      setCurrentPost({ ...currentPost, status: e.target.value })
                                  }
                                  required
                              >
                                  <option value="Draft">Borrador</option>
                                  <option value="Published">Publicado</option>
                                  <option value="Archived">Archivado</option>
                              </select>
                          </label>
                      </div>
                  </div>

                  {/* Full-Width Fields */}
                  <label className="full-width">
                      Resumen:
                      <ReactQuill
                          theme="snow"
                          value={currentPost?.summary || ""}
                          onChange={(value) =>
                              setCurrentPost({ ...currentPost, summary: value })
                          }
                          style={{ height: "150px" }} // Smaller height for Resumen
                      />
                  </label>
                  <p>&nbsp;</p>
                  <label className="full-width">
                      Contenido:
                      <ReactQuill
                          theme="snow"
                          value={currentPost?.content || ""}
                          onChange={(value) =>
                              setCurrentPost({ ...currentPost, content: value })
                          }
                          style={{ height: "250px" }} // Larger height for Contenido
                      />
                  </label>
                  <div className="modal-actions">
                      <button type="submit" className="save-btn">
                          Guardar
                      </button>
                      <button
                          type="button"
                          className="cancel-btn"
                          onClick={closeModal}
                      >
                          Cancelar
                      </button>
                  </div>
              </form>
          </Modal>



        </section>
    );
}

export default Blog;
