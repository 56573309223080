import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './BlogPost.css'; // Archivo de estilos
import dynamoDb from '../../connections/aws-config'; // Conexión a DynamoDB

function BlogPost() {
    const { id } = useParams(); // Obtener el ID del post desde la URL
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchPost = async () => {
        const params = {
            TableName: "smart-mark-blog",
            Key: {
                id: id,
            },
        };

        try {
            const data = await dynamoDb.get(params).promise();
            setPost(data.Item);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching the blog post:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPost();
    }, [id]);

    if (loading) {
        return <p className="loading">Cargando publicación...</p>;
    }

    if (!post) {
        return (
            <div className="not-found">
                <h1>Publicación no encontrada</h1>
                <button onClick={() => navigate("/")}>Volver al Blog</button>
            </div>
        );
    }

    return (
        <div className="blog-post-container">
            <div className="blog-post-header">
                <h1 className="blog-post-title">{post.title}</h1>
                <p className="blog-post-meta">
                    Por <strong>{post.author}</strong> -{" "}
                    {new Date(post.publish_at || post.created_at).toLocaleDateString()}
                </p>
            </div>
            {post.thumbnail_url && (
                <div
                    className="blog-post-image"
                    style={{
                        backgroundImage: `url(${post.thumbnail_url})`,
                    }}
                ></div>
            )}
            <div className="blog-post-content">
                <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
            </div>
            <button className="back-button" onClick={() => navigate("/Blog")}>
                Volver al Blog
            </button>
        </div>
    );
}

export default BlogPost;
