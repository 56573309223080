import React, { useState, useRef } from 'react';
import './Contacto.css';  // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    reply_to: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState(''); // To display the success message
  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form reload
    try {
      await emailjs.sendForm(
        'service_3p98uk1', // Replace with your EmailJS service ID
        'template_6ufy29y', // Replace with your EmailJS template ID
        form.current,
        'Z8lVF-gjCA4RIZzg2' // Replace with your EmailJS public key
      );
      setSuccessMessage('Tu mensaje ha sido enviado con éxito. Gracias por contactarnos.');
      setFormData({ from_name: '', reply_to: '', message: '' }); // Clear the formData state
      form.current.reset(); // Reset the form fields
    } catch (error) {
      console.error('FAILED...', error.text);
      setSuccessMessage('Hubo un problema al enviar tu mensaje. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <main className="contact-page bg-gray-50 min-h-screen py-12 px-4">
      <div className="max-w-7xl mx-auto px-4 mb-16 text-center">
        <h1 className="text-5xl font-bold-800 mb-6">Contactanos</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Sabemos que cada segundo cuenta, ponte en contacto con nosotros y tendrás una respuesta rápidamente.
        </p>
      </div>

      <section className="contact-section right-icon">
        <div className="contact-info">
          <div className="contact-box">
            <a href="mailto:jc@smartmarkconsultores.com" className="contact-item">
              <FontAwesomeIcon icon={faEnvelope} size="4x" className="icon-primary" />
            </a>
          </div>
          <div className="contact-box">
            <a href="https://www.instagram.com/smartmarkconsultores" className="contact-item" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="4x" className="icon-primary" />
            </a>
          </div>
          <div className="contact-box">
            <a href="https://www.linkedin.com/company/smartmark-consultores/" className="contact-item" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="4x" className="icon-primary" />
            </a>
          </div>
          {/* <div className="contact-box">
            <a href="https://www.linkedin.com/in/yourusername" className="contact-item" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="4x" className="icon-primary" />
            </a>
          </div> */}
        </div>
      </section>

      <section className="contact-section">
        <form className="contact-form" ref={form} onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="from_name">Nombre</label>
            <input
              type="text"
              id="from_name"
              name="from_name"
              placeholder="Tu nombre"
              value={formData.from_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="reply_to">Email</label>
            <input
              type="email"
              id="reply_to"
              name="reply_to"
              placeholder="Email"
              value={formData.reply_to}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Descripción</label>
            <textarea
              id="message"
              name="message"
              placeholder="Describe cómo podemos ayudarte"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button type="submit" className="submit-btn">Solicita Tu Estrategia</button>
        </form>

        {successMessage && (
          <p className="success-message mt-4 text-green-600">{successMessage}</p>
        )}
      </section>
    </main>
  );
};

export default Contact;
