import './Header.css';  // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { Routes, Route, NavLink, BrowserRouter, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import HomePage from '../../pages/HomePage/HomePage';
import Servicios from '../../pages/Servicios/servicios';
import Nosotros from '../../pages/Nosotros/Nosotros';
import Contacto from '../../pages/Contacto/Contacto';
import Blog from '../../pages/Blog/Blog';
import Login from '../../containers/Login/Login';
import Admin from '../../containers/Admin/Admin';
import logo from '../../images/Logo_Smart Mark.png';
import BlogPost from '../../pages/Blog/BlogPost'; // Página individual del post



function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const location = useLocation();

    useEffect(() => {
        // Close the menu when the route changes
        setIsMenuOpen(false);
    }, [location]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleLogin = (status) => {
        setIsLoggedIn(status); // Update logged-in state
    };
    return (
        <div>
        <header>
          <div className="navbar">
            <div className="navbar-container">
              <div className="navbar-brand">
                <img src={logo} alt="Brand Logo" width={350} />
              </div>
              <button
                className="menu-toggle"
                onClick={toggleMenu}
                aria-expanded={isMenuOpen}
                aria-label="Toggle navigation menu"
              >
                {isMenuOpen ? (
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                ) : (
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </button>
              <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                <NavLink to="/">Inicio</NavLink>
                <NavLink to="/servicios">Servicios</NavLink>
                <NavLink to="/nosotros">Nosotros</NavLink>
                <NavLink to="/blog">Blog</NavLink>
                <NavLink to="/contacto">Contacto</NavLink>
                {!isLoggedIn && <NavLink to="/login">Login</NavLink>}
                {isLoggedIn && <NavLink to="/admin">Admin</NavLink>}
              </div>
            </div>
          </div>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/post/:id" element={<BlogPost />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/login" element={<Login toggleLogin={toggleLogin} />} />
            <Route path="/admin" element={isLoggedIn ? <Admin /> : <h1>Please log in to access the Admin Page</h1>} />
          </Routes>
        </main>
        </div>
 );
}

export default Header;