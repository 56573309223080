import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './Dashboard.css';
import dynamoDb from '../../../connections/aws-config';

// Register chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Dashboard() {
    const [totalActiveUsers, setTotalActiveUsers] = useState(0);
    const [userTypeCounts, setUserTypeCounts] = useState({
        admin: 0,
        blogger: 0,
        cliente: 0,
    });
    const [totalBlogPosts, setTotalBlogPosts] = useState(0);
    const [blogCategoryCounts, setBlogCategoryCounts] = useState({
        Tecnologia: 0,
        Marketing: 0,
        Estrategia: 0,
    });

    // Fetch active users count and user type distribution
    const fetchActiveUsers = async () => {
        const params = {
            TableName: "smart-mark-users",
            FilterExpression: "active = :active",
            ExpressionAttributeValues: {
                ":active": true,
            },
        };

        try {
            const data = await dynamoDb.scan(params).promise();
            const users = data.Items || [];

            const counts = users.reduce(
                (acc, user) => {
                    acc[user.user_type] = (acc[user.user_type] || 0) + 1;
                    return acc;
                },
                { admin: 0, blogger: 0, cliente: 0 }
            );

            setTotalActiveUsers(users.length);
            setUserTypeCounts(counts);
        } catch (error) {
            console.error("Error fetching active users from DynamoDB:", error);
        }
    };

    // Fetch blog category counts and total blog posts
    const fetchBlogData = async () => {
        const params = {
            TableName: "smart-mark-blog",
        };

        try {
            const data = await dynamoDb.scan(params).promise();
            const posts = data.Items || [];

            const counts = posts.reduce(
                (acc, post) => {
                    acc[post.category] = (acc[post.category] || 0) + 1;
                    return acc;
                },
                { Tecnologia: 0, Marketing: 0, Estrategia: 0 }
            );

            setTotalBlogPosts(posts.length);
            setBlogCategoryCounts(counts);
        } catch (error) {
            console.error("Error fetching blog data from DynamoDB:", error);
        }
    };

    useEffect(() => {
        fetchActiveUsers();
        fetchBlogData();
    }, []);

    // User chart data and options
    const userChartData = {
        labels: ['Admin', 'Blogger', 'Cliente'],
        datasets: [
            {
                label: 'User Types',
                data: [userTypeCounts.admin, userTypeCounts.blogger, userTypeCounts.cliente],
                backgroundColor: ['#4CAF50', '#2196F3', '#FFC107'],
                borderColor: ['#388E3C', '#1976D2', '#FFA000'],
                borderWidth: 1,
            },
        ],
    };

    const userChartOptions = {
        responsive: true,
        plugins: {
            legend: { display: false },
            tooltip: {
                callbacks: { label: (context) => `${context.raw} users` },
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: { beginAtZero: true, ticks: { precision: 0 } },
        },
    };

    // Blog chart data and options
    const blogChartData = {
        labels: ['Tecnologia', 'Marketing', 'Estrategia'],
        datasets: [
            {
                label: 'Blog Categories',
                data: [
                    blogCategoryCounts.Tecnologia,
                    blogCategoryCounts.Marketing,
                    blogCategoryCounts.Estrategia,
                ],
                backgroundColor: ['#FF5733', '#33B5FF', '#75FF33'],
                borderColor: ['#CC462A', '#2A94CC', '#5ECC2A'],
                borderWidth: 1,
            },
        ],
    };

    const blogChartOptions = {
        responsive: true,
        plugins: {
            legend: { display: false },
            tooltip: {
                callbacks: { label: (context) => `${context.raw} posts` },
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: { beginAtZero: true, ticks: { precision: 0 } },
        },
    };

    return (
        <section className="dashboard-grid">
            {/* Users Section */}
            <div className="dashboard-box dashboard-box-split">
                <div className="dashboard-box-left">
                    <h3 className="dashboard-box-title">Total Users</h3>
                    <div className="dashboard-box-number">{totalActiveUsers}</div>
                    <p className="dashboard-box-subtitle">Active Users</p>
                </div>
                <div className="dashboard-box-right">
                    <Bar data={userChartData} options={userChartOptions} />
                </div>
            </div>

            {/* Blog Posts Section */}
            <div className="dashboard-box dashboard-box-split">
                <div className="dashboard-box-left">
                    <h3 className="dashboard-box-title">Total Blog Posts</h3>
                    <div className="dashboard-box-number">{totalBlogPosts}</div>
                    <p className="dashboard-box-subtitle">Published Articles</p>
                </div>
                <div className="dashboard-box-right">
                    <Bar data={blogChartData} options={blogChartOptions} />
                </div>
            </div>
        </section>
    );
}

export default Dashboard;
